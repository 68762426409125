export const MBCardType = {
  BLANK: "Blank",
  DISTANCE_25: "25",
  DISTANCE_50: "50",
  DISTANCE_75: "75",
  DISTANCE_100: "100",
  DISTANCE_200: "200",
  HAZARD_ACCIDENT: "Hazard-Accident",
  HAZARD_FLAT_TIRE: "Hazard-Flat Tire",
  HAZARD_OUT_OF_GAS: "Hazard-Out of Gas",
  HAZARD_SPEED_LIMIT: "Hazard-Speed Limit",
  HAZARD_STOP: "Hazard-Stop",
  REMEDY_END_OF_SPEED_LIMIT: "Remedy-End of Speed Limit",
  REMEDY_GASOLINE: "Remedy-Gasoline",
  REMEDY_GO: "Remedy-Go",
  REMEDY_REPAIRS: "Remedy-Repairs",
  REMEDY_SPARE_TIRE: "Remedy-Spare Tire",
  SAFETY_DRIVING_ACE: "Safety-Driving Ace",
  SAFETY_EXTRA_TANK: "Safety-Extra Tank",
  SAFETY_PUNTURE_PROOF: "Safety-Punture Proof",
  SAFETY_RIGHT_OF_WAY: "Safety-Right of Way",
};
